import { useTranslation } from "react-i18next";

export const getGreeting = () => {
  const { t } = useTranslation();
  const myDate = new Date();
  const hrs = myDate.getHours();

  if (hrs < 12) return t("overview_page__good_morning");
  else if (hrs >= 12 && hrs <= 17) return t("overview_page__good_afternoon");
  else if (hrs >= 17 && hrs <= 24) return t("overview_page__good_evening");
};
