import { Theme, useMediaQuery } from "@mui/material";
import { Step, Steps } from "intro.js-react";
import React, { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

interface Props {
  props?: string;
  open?: boolean;
  onGuideCompleted?: () => any;
}

const GuiderComponent: FC<Props> = ({ open = false, onGuideCompleted }) => {
  const { t } = useTranslation();
  const [enabled, setEnabled] = useState(
    open || localStorage.getItem("onboarding") === null ? true : false
  );
  const hideSide = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("lg")
  );

  useEffect(() => {
    if (open) {
      setEnabled(true);
    }
  }, [open]);
  const [initialStep] = useState(0);
  const steps: Step[] = [
    {
      title: t("guider__new_joiners_guide__steps__step1__title"),
      intro: t("guider__new_joiners_guide__steps__step1__description"),
      tooltipClass: "introjs-custom",
    },
    {
      element: ".howToSection",
      title: t("guider__new_joiners_guide__steps__step2__title"),
      intro: t("guider__new_joiners_guide__steps__step2__description"),
      position: "top",
    },
    {
      element: ".outletSelector",
      title: t("guider__new_joiners_guide__steps__step3__title"),
      intro: t("guider__new_joiners_guide__steps__step3__description"),
      position: "right",
    },
    {
      element: ".navigation",
      title: t("guider__new_joiners_guide__steps__step4__title"),
      intro: t("guider__new_joiners_guide__steps__step4__description"),
      position: "right",
    },
    {
      title: t("guider__new_joiners_guide__steps__step5__title"),
      element: "#settings-button",
      intro: t("guider__new_joiners_guide__steps__step5__description"),
      position: "left",
    },
    {
      title: t("guider__new_joiners_guide__steps__step6__title"),
      intro: t("guider__new_joiners_guide__steps__step6__description"),
      tooltipClass: "introjs-custom",
    },
  ];
  const onExit = () => {
    setEnabled(false);
    localStorage.setItem("onboarding", "false");
    onGuideCompleted && onGuideCompleted();
  };
  const forceScrollTo = (el: Element | null, tries: number) => {
    if (tries === 10) {
      return;
    }

    const elTop = el?.getBoundingClientRect().top!;
    const top = window.scrollY + elTop - 72; // 64px from the header plus 8px margin
    const scrollHeight = Math.max(
      document.body.scrollHeight,
      document.documentElement.scrollHeight,
      document.body.offsetHeight,
      document.documentElement.offsetHeight,
      document.body.clientHeight,
      document.documentElement.clientHeight
    );
    const scrollPos = window.scrollY + window.innerHeight;

    if (
      elTop > 0 &&
      (Math.floor(window.scrollY) === Math.floor(top) ||
        Math.ceil(window.scrollY) === Math.ceil(top) ||
        Math.floor(scrollPos) === scrollHeight ||
        Math.ceil(scrollPos) === scrollHeight)
    ) {
      return;
    }
    window.scrollTo({
      top,
      left: 0,
    });
    window.setTimeout(() => forceScrollTo(el, tries + 1), 100);
  };
  const onBeforeChange = (nextStepIndex: number) => {
    if (
      (nextStepIndex === 2 || nextStepIndex === 3) &&
      (document.querySelector(`.simplebar-wrapper`)?.getBoundingClientRect()
        .left ?? 0) === -280
    ) {
      document.getElementById("menu-button")?.click();
      setTimeout(() => {
        (
          document.querySelector(
            nextStepIndex === 2 ? ".introjs-nextbutton" : ".introjs-prevbutton"
          )! as HTMLElement
        ).click();
      }, 100);
      return false;
    } else if (
      (nextStepIndex === 2 || nextStepIndex === 3) &&
      (document.querySelector(`.simplebar-wrapper`)?.getBoundingClientRect()
        .left ?? 0) < 0
    ) {
      setTimeout(() => {
        (
          document.querySelector(
            nextStepIndex === 2 ? ".introjs-nextbutton" : ".introjs-prevbutton"
          )! as HTMLElement
        ).click();
      }, 100);
      return false;
    } else if (
      hideSide &&
      nextStepIndex === 1 &&
      (document.querySelector(`.simplebar-wrapper`)?.getBoundingClientRect()
        .left ?? 0) === 0
    ) {
      document.getElementById("menu-button")?.click();
    } else if (
      hideSide &&
      nextStepIndex === 4 &&
      (document.querySelector(`.simplebar-wrapper`)?.getBoundingClientRect()
        .left ?? 0) === 0
    ) {
      document.getElementById("menu-button")?.click();
    }
  };
  const onAfterChange = (newStepIndex: number) => {
    if (newStepIndex === 1) {
      setTimeout(
        () => forceScrollTo(document.querySelector(".introjs-tooltip"), 0),
        200
      );
    }
    (document.querySelector(".introjs-nextbutton")! as HTMLElement).focus();
  };

  return enabled ? (
    <Steps
      enabled={enabled}
      steps={steps}
      onBeforeChange={onBeforeChange}
      onAfterChange={onAfterChange}
      initialStep={initialStep}
      onExit={onExit}
      options={{
        hidePrev: true,
        exitOnOverlayClick: false,
        nextToDone: true,
      }}
    />
  ) : null;
};

export default GuiderComponent;
