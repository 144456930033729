import { Box, Button, Card, Typography } from "@mui/material";
import { ReactNode } from "react";
//import OverviewCardWrapper from './styles';

import { Close } from "../../../icons/close";
import useAppMediaQuery from "../../../hooks/use-media-query";

interface OverviewBannerProps {
  onDismiss?: () => void;
  content?: {
    title?: string;
    description?: string;
    close?: {
      text?: string;
      isCloseble?: boolean;
    };
    category?: {
      text?: string;
      icon?: any;
    };
    picture?: any;
    callToAction?:
      | {
          text?: string;
          icon?: any;
          onClick?: () => void;
          children?: ReactNode;
        }[]
      | ReactNode[]
      | any;
    badge?: any;
    smallCard?: boolean;
  };

  styles?: {
    backgroundColor?: string;
    color?: string;
  };
  children?: ReactNode;
  isDismissed?: boolean;
  handleSetDismessed?: any;
}

export const OverviewCardComponent = ({
  content,
  styles,
  isDismissed = false,
  handleSetDismessed,
}: OverviewBannerProps) => {
  const isMobile = useAppMediaQuery("(max-width:1075px)");
  return !isDismissed ? (
    <Card
      sx={{
        //alignItems: 'center',
        backgroundColor: "primary.main",
        color: "primary.contrastText",
        display: "flex",
        height: "100%",
        alignItems: "flex-start",
        maxHeight: isMobile ? "100%" : "unset",
        flexDirection: {
          xs: "column",
          md: "row",
        },
        ...styles,
      }}
    >
      <div
        style={{
          padding: "0.5rem",
          display: "flex",
          flexDirection: "column",
          width: "100%",
          position: "relative",
          height: "100%",
        }}
      >
        {content?.category && (
          <Box
            className="mainBanner__section--category"
            sx={{
              display: "flex",
              alignItems: "center",
              marginBottom: "0.5rem",
              color: "primary.main",
            }}
          >
            {content?.category.icon}
            <Typography style={{ marginLeft: "1rem", fontSize: "1.4rem" }}>
              {content?.category?.text}
            </Typography>
          </Box>
        )}

        <Box
          className="mainBanner__section--content"
          sx={{
            display: "flex",
            flexDirection: isMobile
              ? "column"
              : {
                  xs: "column",
                  md: "row",
                },
            mb: {
              md: 0,
              xs: "2rem",
            },
            height: "100%",
          }}
        >
          {content?.picture && (
            <Box
              sx={
                isMobile
                  ? {
                      /* mr: 4, */
                      width: "100%",
                      height: "175px",
                      "& svg": {
                        height: "100%",
                        width: "100%",
                        objectPosition: "center",
                        objectFit: "contain",
                      },
                    }
                  : {
                      mr: 4,
                      width: "auto",
                      height: "auto",
                      display: "flex",
                      alignItems: "center",
                      "& svg": {
                        height: 185,
                        width: "auto",
                        ml: 5,
                      },
                    }
              }
            >
              {content?.picture}
            </Box>
          )}
          <div
            style={{
              width: "100%",
              height: isMobile ? "100%" : "unset",
            }}
          >
            <Box sx={{ height: "calc(100% - 65px)" }}>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                {
                  //@ts-ignore
                  content?.badge && content?.badge
                }
              </div>
              <Typography
                color="inherit"
                sx={{ mt: 2 }}
                variant={isMobile ? "h6" : "h5"}
              >
                {content?.title}
              </Typography>
              <Typography
                color="inherit"
                sx={{ mt: 1 }}
                variant={isMobile ? "body2" : "subtitle2"}
              >
                {content?.description}
              </Typography>
            </Box>
            {content?.callToAction && (
              <Box sx={{ marginTop: "1rem" }}>
                <Box
                  sx={{
                    display: "flex",
                    marginTop: "1rem",
                    justifyContent: "flex-end",
                    height: "100%",
                  }}
                >
                  {content?.callToAction}
                </Box>
              </Box>
            )}
          </div>
        </Box>
        {content?.close?.isCloseble && (
          <Button
            color="inherit"
            onClick={() => {
              localStorage.setItem(
                "hideMainBanner",
                new Date(new Date().getTime() + 1000 * 60 * 60 * 24).toString()
              );
              handleSetDismessed(true);
            }}
            variant="text"
            sx={{
              position: "absolute",
              top: "1rem",
              right: "0.1rem",
              justifyContent: "center",
            }}
          >
            {<Close style={{ fontSize: "1.2rem" }} />}
          </Button>
        )}
      </div>
    </Card>
  ) : null;
};
