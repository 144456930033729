import { EACTIONS } from "../../action.enum";
import { IDateRangeAction } from "./date-range.action";

export function setDateRangeAction(
  dateRange: Types.DateRange
): IDateRangeAction {
  return {
    type: EACTIONS.SET_DATE_RANGE,
    payload: dateRange,
  };
}

export function removeDateRangeAction(): IDateRangeAction {
  return {
    type: EACTIONS.REMOVE_DATE_RANGE,
    payload: null,
  };
}
