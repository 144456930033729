import { Box, Typography, IconButton, Popover, Button } from "@mui/material";

import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Close as CloseIcon } from "../../icons/close";
import { apiRequestHandler } from "../../api/requestHandler";
import { IReduxState } from "../../redux/app_store";
import { useSelector, useDispatch } from "react-redux";
import { CircularLoader } from "../share/circular-loader";
import { setMockedDataAction } from "../../redux/action/mocked-data/index";

export const ConfirmationAlertPopover = (props) => {
  const { selectedUnit, selectedOutlet, mockedData } = useSelector(
    (store: IReduxState) => store
  );

  const [inProgress, setInProgress] = useState(false);

  const { confirmationTo, summaryResponse } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const alertHandler = async () => {
    if (props.isMock) {
      let updatedMockedData = { ...mockedData };
      updatedMockedData.priceAlert.priceAlert =
        updatedMockedData.priceAlert?.priceAlert?.filter((item) => {
          return item.id !== props.article?.id;
        });
      updatedMockedData.priceAlert.total =
        updatedMockedData.priceAlert?.priceAlert?.length || 0;
      dispatch(setMockedDataAction(updatedMockedData));
      return;
    }
    setInProgress(true);
    await apiRequestHandler(
      `/api/general/price-alert/operation/article/${props.article?.id}`,
      "POST",
      {
        outletId: selectedOutlet?.id,
        article: props.article,
        operation: confirmationTo,
        startDate: selectedOutlet?.firstRecordAt,
        endDate: new Date().toISOString().split("T")[0],
      },
      {
        "x-unit-id": selectedUnit?.id,
        "Content-Type": "application/json",
      }
    );

    props.onClose();
    summaryResponse?.refetch();
    setInProgress(false);
  };

  return (
    <Popover
      open={props.open}
      anchorEl={props.anchorEl}
      onClose={props.onClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
    >
      <Box sx={{ padding: 2, maxWidth: "400px", minWidth: "300px" }}>
        <Box
          sx={{
            display: "flex",
            mb: 4,
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="h6" sx={{ pr: 3 }}>
            {confirmationTo === "resolve"
              ? t(
                  "account_page__notifications__alerts__modals__resolve__resolve_title"
                )
              : t(
                  "account_page__notifications__alerts__modals__resolve__ignore_title"
                )}
          </Typography>
          <IconButton onClick={props.onClose}>
            <CloseIcon style={{ fontSize: "14px" }} />
          </IconButton>
        </Box>
        <Box>
          <Box sx={{ display: "flex" }}>
            <Typography>
              {confirmationTo === "resolve"
                ? t(
                    "account_page__notifications__alerts__modals__resolve__messages__resolve_all_invoice"
                  )
                : t(
                    "account_page__notifications__alerts__modals__resolve__messages__ignore_all_invoice"
                  )}
            </Typography>
          </Box>
          <Box sx={{ display: "flex", justifyContent: "space-around", mt: 2 }}>
            {inProgress ? (
              <CircularLoader />
            ) : (
              <Button variant="outlined" onClick={alertHandler}>
                {confirmationTo === "resolve"
                  ? t(
                      "account_page__notifications__alerts__modals__resolve__resolve_button"
                    )
                  : t(
                      "account_page__notifications__alerts__modals__resolve__ignore_button"
                    )}
              </Button>
            )}
          </Box>
        </Box>
      </Box>
    </Popover>
  );
};
