import { useRouter } from "next/router";
import { arrayString } from "../utils/string.utils";

/**
 * useQueryParams hook redirect to the route with params
 *
 * @remarks
 * This component is part of the {@link core-library#front_end_parsly | Front_end subsystem}.
 *
 * @param queryName  - query name
 * @param queryValue  - The query value
 *
 * @beta
 */

export const useQueryParams = () => {
  const router = useRouter();
  const query = router.query;

  return (queryName: string, queryValue: string) => {
    const params = query[queryName]
      ? Object.keys(query)
          .map((key) => {
            const values = arrayString(query[key] ?? "").filter((e) => !!e);
            if (key == queryName) {
              if (values.indexOf(queryValue) > -1)
                values.splice(values.indexOf(queryValue), 1);
              else values.push(queryValue);
            }

            return values
              .map((value) => `${key}=${encodeURIComponent(value)}`)
              .join("&");
          })
          .join("&")
      : Object.keys(query)
          .concat(queryName)
          .map((key) =>
            arrayString(query[key] ?? queryValue)
              .map((value) => `${key}=${encodeURIComponent(value)}`)
              .join("&")
          );

    router.push(`${router.pathname}?${params}`);
  };
};
