export const mockedMainSummery = {
  isLoading: false,
  data: {
    summary: {
      totalSum: {
        amount: "3401.32",
        currency: "SEK",
      },
      totalCount: "6",
      monthly: [
        {
          yearMonth: "2023-01",
          sum: {
            amount: "1234.5",
            currency: "SEK",
          },
          count: "2",
        },
        {
          yearMonth: "2023-02",
          sum: null,
          count: "0",
        },
      ],
    },
    supplier: {
      id: "2283478909880108310",
      countryId: "SE",
      legalName: "Andys - Clafra Aktiebolag",
      displayName: "Andys Frukt",
      legalAddress: "Uppköparvägen 6, 120 44 Årsta",
      legalEntityNumber: "556303-2696",
      contactEmail: "kundupplagg@menigo.se",
      contactTel: "+46 8 19 63 07",
      website: "www.andysfrukt.se",
      street: "Uppköparvägen 6",
      zipCode: "12044",
      city: "Årsta",
      categories: [
        {
          id: "2287835481687197084",
          name: "General",
          color: "#F24868",
          parent: null,
        },
      ],
      hasParser: true,
      instagram: null,
      eshop: null,
      description: null,
      cutTime: null,
      created: "2020-04-09T11:32:00.274Z",
      updated: "2021-08-09T07:52:24.150120Z",
    },
    lastInvoice: {
      id: "6663",
      outletId: "30",
      supplierId: "2283478909880108310",
      type: "Invoice",
      number: "3000",
      date: "2023-03-01",
      amount: {
        amount: "1562.30",
        currency: "SEK",
      },
      itemCount: 2,
    },
    alerts: "5",
  },
};
