import { Box, Button, FormControl, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IReduxState } from "../redux/app_store";

import BannerIllustration from "../../public/img/main-illustration.svg";
import ShareIllustration from "../../public/img/sharing-illustration.svg";
import AwayIllustration from "../../public/img/away-illustration.svg";
import TestingIllustration from "../../public/img/testing-illustration.svg";
import PremiumLockIllustration from "../../public/img/main-cta-premium-illustration.svg";
import TrialModeIllustration from "../../public/img/trial-mode-illustration.svg";

import { getGreeting } from "../utils/get-greeting";
import { congratulationEffect } from "../utils/confettis";
import OverviewCardComponent from "./overview/OverviewCard";
import { AddUser } from "../icons/add-user";
import { Invoice } from "../icons/invoice";
import { Truck } from "../icons/truck";
import { useTranslation } from "react-i18next";
import { capitalize } from "../utils/capitalize";
import StagingFlag from "./staging-flag";
import config from "../utils/config";
import { FilterSelector } from "./share/filter-selector";
import GuiderComponent from "./guider";
import { useRouter } from "next/router";
import { apiRequestHandler } from "../api/requestHandler";
import { setActivityAction } from "../redux/action/activity";
import { CircularLoader } from "./share/circular-loader";
import { addMinutes } from "date-fns";

export const MainContent: any = ({ onGuideCompleted }) => {
  const NEXT_PUBLIC_appEnv = config.appEnv;
  const { t } = useTranslation();
  const router = useRouter();
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useDispatch();
  const [renderCTA, setRenderCTA] = React.useState<string>("normalAccess");
  const [longTimeWithoutAccess, setLongTimeWithoutAccess] =
    React.useState<boolean>(false);
  const { user, selectedOutlet, userFlags } = useSelector(
    (store: IReduxState) => store
  );
  const [activity, setActivity] = useState<any>(null);

  const handleActivity = async () => {
    const data: any = await apiRequestHandler(
      `/api/activity?type=activity`,
      "GET",
      null
    );
    if (data) {
      dispatch(setActivityAction(data));
      setActivity(data);
      ctaStateHandler();
      setIsLoading(false);
    }
  };
  useEffect(() => {
    if (
      router.query?.subscription &&
      router.query?.subscription === "success" &&
      (userFlags?.tier === "premium" || userFlags?.tier === "pro")
    ) {
      setRenderCTA("subscriptionPaid");
    } else if (!activity || activity?.lastLogin === "") {
      handleActivity();
    } else {
      ctaStateHandler();
      setIsLoading(false);
    }
  }, [activity, userFlags]);
  const variant = {
    newJoiner: {
      content: {
        close: {
          text: t("overview_page__main_cta__general__close_button"),
          isCloseble: true,
        },
        title: t("overview_page__main_cta__new_joiners__title"),
        description: t("overview_page__main_cta__new_joiners__description"),
        callToAction: [
          <Button
            onClick={() =>
              router.push("/setting/outlet?tab=team&id=" + selectedOutlet?.id)
            }
            variant="contained"
            style={{ marginRight: "0.5rem" }}
            key={2}
            sx={{
              width: {
                xs: "100%",
                sm: "unset",
              },
              color: "#222020",
              backgroundColor: "#FFF",
              ":hover": { backgroundColor: "#FFFFFF80" },
            }}
            endIcon={<AddUser fontSize="medium" style={{ width: "1.6rem" }} />}
          >
            {t("overview_page__main_cta__new_joiners__action_button")}
          </Button>,
        ],
        picture: <ShareIllustration />,
      },
      customStyles: { backgroundColor: "#ECCCC6", color: "#222020" },
    },
    withoutActivity: {
      content: {
        close: {
          text: t("overview_page__main_cta__general__close_button"),
          isCloseble: true,
        },
        title: t("overview_page__main_cta__without_activity__title"),
        description: t(
          "overview_page__main_cta__without_activity__description"
        ),
        callToAction: [
          <Button
            onClick={() => router.push(`/tutorials`)}
            variant="contained"
            key={2}
            style={{ marginRight: "0.5rem" }}
            sx={{
              width: {
                xs: "100%",
                sm: "unset",
              },
              color: "#222020",
              backgroundColor: "#FFF",
              ":hover": { backgroundColor: "#FFFFFF80" },
            }}
            endIcon={<Invoice fontSize="medium" style={{ width: "1.6rem" }} />}
          >
            {t("overview_page__main_cta__without_activity__action_button")}
          </Button>,
        ],
        picture: <AwayIllustration />,
      },
      customStyles: { backgroundColor: "#DE7A6D", color: "#FFFFFF" },
    },
    firstExperience: {
      content: {
        close: {
          text: t("overview_page__main_cta__general__close_button"),
          isCloseble: true,
        },
        title: t("overview_page__main_cta__first_experience__title"),
        description: t(
          "overview_page__main_cta__first_experience__description"
        ),
        callToAction: [
          <Button
            key={2}
            onClick={() => router.push("/sourcing/suppliers")}
            variant="contained"
            style={{ marginRight: "0.5rem" }}
            sx={{
              width: {
                xs: "100%",
                sm: "unset",
              },
              color: "#222020",
              backgroundColor: "#FFF",
              ":hover": { backgroundColor: "#FFFFFF80" },
            }}
            endIcon={<Truck fontSize="medium" style={{ width: "1.6rem" }} />}
          >
            {t("overview_page__main_cta__first_experience__action_button")}
          </Button>,
        ],
        picture: <TestingIllustration />,
      },
      customStyles: { backgroundColor: "#1C938E", color: "#FFFFFF" },
    },
    normalAccess: {
      content: {
        close: {
          text: t("overview_page__main_cta__general__close_button"),
          isCloseble: true,
        },
        title: t("overview_page__main_cta__normal_access__title"),
        description: t("overview_page__main_cta__normal_access__description"),
        callToAction: [],
        picture: <BannerIllustration />,
      },
      customStyles: { backgroundColor: "#FEEAC5", color: "#222020" },
    },
    activityFallback: {
      content: {
        close: {
          text: t("overview_page__main_cta__general__close_button"),
          isCloseble: true,
        },
        title: t("overview_page__main_cta__normal_access__title"),
        description: t("overview_page__main_cta__normal_access__description"),
        callToAction: [],
        picture: <BannerIllustration />,
      },
      customStyles: { backgroundColor: "#FEEAC5", color: "#222020" },
    },
    subscriptionPaid: {
      content: {
        close: {
          text: t("overview_page__main_cta__general__close_button"),
          isCloseble: true,
        },
        title: t("overview_page__main_cta__subscription_paid__title"),
        description: t(
          "overview_page__main_cta__subscription_paid__description"
        ),
        callToAction: [],
        picture: <PremiumLockIllustration />,
      },
      customStyles: { backgroundColor: "#FFDEDA", color: "#222020" },
    },
    trialMode: {
      content: {
        close: {
          text: t("overview_page__main_cta__general__close_button"),
          isCloseble: true,
        },
        title: t("overview_page__main_cta__trial_mode__title"),
        description: t("overview_page__main_cta__trial_mode__description"),
        callToAction: [],
        picture: <TrialModeIllustration />,
      },
      customStyles: { backgroundColor: "#C7E0E0", color: "#222020" },
    },
  };

  const ctaStateHandler = () => {
    if (
      router.query?.subscription &&
      router.query?.subscription === "success" &&
      (userFlags?.tier === "premium" || userFlags?.tier === "pro")
    ) {
      setRenderCTA("subscriptionPaid");
    } else if (
      userFlags?.tier === "trial" &&
      (!localStorage.getItem("trialBanner")! ||
        new Date(localStorage.getItem("trialBanner")!) > new Date())
    ) {
      setRenderCTA("trialMode");
      if (!localStorage.getItem("trialBanner")!) {
        localStorage.setItem(
          "trialBanner",
          addMinutes(new Date(), 15).toISOString()
        );
      }
    } else if (!activity || activity?.lastLogin === "") {
      setRenderCTA("activityFallback");
    } else {
      setRenderCTA("normalAccess");
      const { accountStatus, accountStatusHistory, loginHistory }: any =
        activity;

      const accountStatusHistoryElement =
        accountStatusHistory[accountStatusHistory?.length - 1];

      const firstExp =
        accountStatusHistoryElement.status === "ONBOARDED" &&
        new Date(new Date().setMinutes(new Date().getMinutes() - 30)) <
          new Date(accountStatusHistoryElement.date);
      const state = accountStatus;
      const previousLogin =
        loginHistory[
          loginHistory.length >= 2
            ? loginHistory.length - 2
            : loginHistory.length - 1
        ];
      setLongTimeWithoutAccess(
        new Date(previousLogin) <=
          new Date(new Date().setMonth(new Date().getMonth() - 2))
      );
      if (state !== "ONBOARDED" && loginHistory.length === 1) {
        setRenderCTA("newJoiner");
      }
      if (state !== "ONBOARDED" && loginHistory.length > 1) {
        setRenderCTA("withoutActivity");
      }
      if (state === "ONBOARDED" && firstExp) {
        setRenderCTA("firstExperience");
      }
    }
  };
  useEffect(() => {
    ctaStateHandler();
  }, []);
  const [isDismissed, setIsDismissed] = useState(
    !!localStorage.getItem("hideMainBanner") ? true : false
  );
  useEffect(() => {
    if (new Date(localStorage.getItem("hideMainBanner")!) < new Date()) {
      localStorage.removeItem("hideMainBanner");
      setIsDismissed(false);
    }
  }, []);
  if (renderCTA === "firstExperience") {
    congratulationEffect("firstExperienceConfettis");
  }
  let welcomeMessage = `${
    longTimeWithoutAccess
      ? t("overview_page__long_time_greeting")
      : getGreeting()
  }, ${capitalize(user?.fullName.split(" ")[0])}`;
  if (renderCTA === "newJoiner") {
    welcomeMessage = `${t("overview_page__general_greeting")}, ${
      user?.fullName.split(" ")[0]
    }`;
  }
  return isLoading ? (
    <CircularLoader />
  ) : (
    <>
      {renderCTA === "newJoiner" && (
        <GuiderComponent onGuideCompleted={onGuideCompleted} />
      )}
      <Box id="help" sx={{ mb: 3, pt: "3rem" }}>
        <Grid container justifyContent="space-between" spacing={3}>
          <Grid item sx={{ display: "flex", alignItems: "flex-end" }}>
            <Typography variant="h4">{welcomeMessage}</Typography>
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ mb: 3 }}>
        <OverviewCardComponent
          key={`OverviewCard${capitalize(renderCTA)}`}
          content={variant[renderCTA].content}
          styles={variant[renderCTA].customStyles}
          isDismissed={isDismissed}
          handleSetDismessed={setIsDismissed}
        />
      </Box>
      {NEXT_PUBLIC_appEnv === "staging" ? (
        <StagingFlag
          name={`Rotate CTA`}
          hints={[
            "overview_page__long_time_greeting",
            "overview_page__general_greeting",
            "overview_page__main_cta__general__close_button",
            "overview_page__main_cta__new_joiners__title",
            "overview_page__main_cta__new_joiners__description",
            "overview_page__main_cta__new_joiners__action_button",
          ]}
        >
          <FormControl sx={{ mt: 2, ml: -1, minWidth: 240 }}>
            <FilterSelector
              initialValue={{
                label: ``,
                key: renderCTA,
                value: renderCTA,
                option: {
                  id: renderCTA,
                },
              }}
              label={"CTA State"}
              options={[
                {
                  label: `First Experience`,
                  key: `firstExperience`,
                  value: `firstExperience`,
                  option: {
                    id: `firstExperience`,
                  },
                },
                {
                  label: `New Joiner`,
                  key: `newJoiner`,
                  value: `newJoiner`,
                  option: {
                    id: `newJoiner`,
                  },
                },
                {
                  label: `Without Activity`,
                  key: `withoutActivity`,
                  value: `withoutActivity`,
                  option: {
                    id: `withoutActivity`,
                  },
                },
                {
                  label: `Regular Access`,
                  key: `normalAccess`,
                  value: `normalAccess`,
                  option: {
                    id: `normalAccess`,
                  },
                },
                {
                  label: `Fallback`,
                  key: `activityFallback`,
                  value: `activityFallback`,
                  option: {
                    id: `activityFallback`,
                  },
                },
                {
                  label: `Premium`,
                  key: `subscriptionPaid`,
                  value: `subscriptionPaid`,
                  option: {
                    id: `subscriptionPaid`,
                  },
                },
                {
                  label: `Trial`,
                  key: `trialMode`,
                  value: `trialMode`,
                  option: {
                    id: `trialMode`,
                  },
                },
              ]}
              onSelect={(e: any) => {
                const option: any = [
                  {
                    label: `First Experience`,
                    key: `firstExperience`,
                    value: `firstExperience`,
                    option: {
                      id: `firstExperience`,
                    },
                  },
                  {
                    label: `New Joiner`,
                    key: `newJoiner`,
                    value: `newJoiner`,
                    option: {
                      id: `newJoiner`,
                    },
                  },
                  {
                    label: `Without Activity`,
                    key: `withoutActivity`,
                    value: `withoutActivity`,
                    option: {
                      id: `withoutActivity`,
                    },
                  },
                  {
                    label: `Regular Access`,
                    key: `normalAccess`,
                    value: `normalAccess`,
                    option: {
                      id: `normalAccess`,
                    },
                  },
                  {
                    label: `Fallback`,
                    key: `activityFallback`,
                    value: `activityFallback`,
                    option: {
                      id: `activityFallback`,
                    },
                  },
                  {
                    label: `Premium`,
                    key: `subscriptionPaid`,
                    value: `subscriptionPaid`,
                    option: {
                      id: `subscriptionPaid`,
                    },
                  },
                  {
                    label: `Trial`,
                    key: `trialMode`,
                    value: `trialMode`,
                    option: {
                      id: `trialMode`,
                    },
                  },
                ].find((item: any) => item.option.id === e.id);
                setRenderCTA(option.value);
              }}
              // isMobile={isMobile}
              //label="Period"

              sx={{
                "& .MuiSelect-select": {
                  minHeight: "auto",
                },
                width: "100%",
              }}
            />
          </FormControl>
        </StagingFlag>
      ) : null}
    </>
  );
};
