import { createSvgIcon } from "@mui/material/utils";

export const BadgeCheck = createSvgIcon(
  <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.53396 2.90972C9.82048 2.807 11.0418 2.30098 12.024 1.46372C13.1333 0.518902 14.5428 0 16 0C17.4571 0 18.8666 0.518902 19.976 1.46372C20.9581 2.30098 22.1794 2.807 23.466 2.90972C24.9188 3.02583 26.2828 3.65565 27.3134 4.68625C28.344 5.71686 28.9739 7.08086 29.09 8.53372C29.192 9.81972 29.698 11.0417 30.536 12.0237C31.4808 13.133 31.9997 14.5426 31.9997 15.9997C31.9997 17.4569 31.4808 18.8664 30.536 19.9757C29.6987 20.9579 29.1927 22.1792 29.09 23.4657C28.9739 24.9186 28.344 26.2826 27.3134 27.3132C26.2828 28.3438 24.9188 28.9736 23.466 29.0897C22.1794 29.1924 20.9581 29.6985 19.976 30.5357C18.8666 31.4805 17.4571 31.9994 16 31.9994C14.5428 31.9994 13.1333 31.4805 12.024 30.5357C11.0418 29.6985 9.82048 29.1924 8.53396 29.0897C7.0811 28.9736 5.7171 28.3438 4.68649 27.3132C3.65589 26.2826 3.02608 24.9186 2.90996 23.4657C2.80725 22.1792 2.30123 20.9579 1.46396 19.9757C0.519146 18.8664 0.000244141 17.4569 0.000244141 15.9997C0.000244141 14.5426 0.519146 13.133 1.46396 12.0237C2.30123 11.0415 2.80725 9.82024 2.90996 8.53372C3.02608 7.08086 3.65589 5.71686 4.68649 4.68625C5.7171 3.65565 7.0811 3.02583 8.53396 2.90972ZM23.414 13.4137C23.7783 13.0365 23.9799 12.5313 23.9753 12.0069C23.9708 11.4825 23.7604 10.9809 23.3896 10.6101C23.0188 10.2393 22.5172 10.0289 21.9928 10.0244C21.4684 10.0198 20.9632 10.2214 20.586 10.5857L14 17.1717L11.414 14.5857C11.0368 14.2214 10.5316 14.0198 10.0072 14.0244C9.48277 14.0289 8.98114 14.2393 8.61033 14.6101C8.23951 14.9809 8.02917 15.4825 8.02461 16.0069C8.02006 16.5313 8.22165 17.0365 8.58596 17.4137L12.586 21.4137C12.961 21.7887 13.4696 21.9993 14 21.9993C14.5303 21.9993 15.0389 21.7887 15.414 21.4137L23.414 13.4137Z"
      fill="#29A776"
    />
  </svg>,
  "BadgeCheck"
);
