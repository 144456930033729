import { useTheme } from "@mui/material";
// import { useTranslation } from "react-i18next";
import { useRouter } from "next/router";
import { IOptionItem, Selector } from "./selector";
import { useQueryParams } from "../../hooks/use-query-params";
import { useEffect } from "react";

interface IProps<T> {
  isMobile?: boolean;
  label?: JSX.Element | string;
  options: Array<IOptionItem<T>>;
  onSelect?(option: T): void;
  queryName?: string;
  initialValue?: IOptionItem<T>;
  multiple?: T[] | IOptionItem<T>[];
  sx?: any;
  renderValue?: (selected: T | T[]) => string | JSX.Element;
  size?: "small" | "medium";
  disabled?: boolean;
}

export function FilterSelector<T>({
  label,
  isMobile,
  options,
  onSelect,
  queryName,
  initialValue,
  multiple,
  sx,
  ...otherProps
}: IProps<T>) {
  // const { t } = useTranslation();
  const theme = useTheme();
  const router = useRouter();
  const queryParams = useQueryParams();
  const selectQuery =
    queryName && router.query[queryName]
      ? !!multiple
        ? multiple
        : router.query[queryName]
      : !!multiple
      ? multiple
      : initialValue?.value ?? "";

  const handleSelect = (value: T, key?: string) => {
    if (queryName) {
      queryParams(queryName, key || "");
    }
    onSelect?.(value);
  };
  useEffect(() => {
    if (initialValue && selectQuery === "") {
      handleSelect(initialValue.option, initialValue.key);
    }
  }, []);

  return (
    <Selector
      label={label || undefined}
      variant="outlined"
      sx={{
        backgroundColor: isMobile ? theme.palette.background.paper : "",
        cursor: "pointer",
        mb: 2,

        "& .MuiSelect-icon": {
          fontSize: "2.4rem",
        },

        ...sx,
      }}
      value={
        multiple
          ? (selectQuery as unknown as T[])
          : (selectQuery as unknown as T)
      }
      fullWidth
      size={isMobile ? "small" : undefined}
      options={options || []}
      handleSelect={handleSelect}
      multiple={!!multiple}
      {...otherProps}
    />
  );
}
