import React, { ImgHTMLAttributes, useEffect, useState } from "react";
import { imageOnError } from "../../utils/image-url";

interface IProps extends ImgHTMLAttributes<HTMLImageElement> {
  defaultImg?: string;
  brokenImg?: string;
  mainImage?: string;
  opacity?: string;
}

const ImageApp = (props: IProps) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [isBroken, setIsBroken] = useState<boolean>(false);

  useEffect(() => {
    setIsBroken(false);
  }, [props.mainImage]);

  const onLoad = () => {
    setLoading(false);
  };

  const imgTagOriginAttr = (
    props: IProps
  ): ImgHTMLAttributes<HTMLImageElement> => {
    let newP = { ...props };
    delete newP.mainImage;
    delete newP.brokenImg;
    delete newP.defaultImg;

    return newP;
  };

  return (
    <>
      {loading ? (
        <img
          {...imgTagOriginAttr(props)}
          style={{ opacity: "0.2" }}
          src={props.defaultImg}
          alt=""
        />
      ) : null}
      <img
        {...imgTagOriginAttr(props)}
        style={{
          display: !loading ? "" : "none",
          opacity: props.opacity ? props.opacity : isBroken ? "0.2" : "1",
          ...props.style,
        }}
        src={props.mainImage}
        onLoad={() => onLoad()}
        onError={(e) => {
          setIsBroken(true);
          return imageOnError(e, props.brokenImg!);
        }}
        alt=""
      />
    </>
  );
};

export { ImageApp };
