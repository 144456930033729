import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  styled,
  Tooltip,
  tooltipClasses,
  TooltipProps,
  Typography,
} from "@mui/material";
import { useState, FC, useEffect } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import config from "../../utils/config";
import InfoIcon from "@mui/icons-material/Info";
import { useTranslation } from "react-i18next";

interface Props {
  name: string;
  hints?: string[];
  children?: React.ReactNode;
}
type Language = "en" | "sv";

type LanguageOptions = {
  [key in Language]: {
    icon: string;
    label: string;
    id: number;
  };
};
const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: "100%",
    fontSize: "1rem",
    border: "1px solid #dadde9",
  },
}));
const StagingFlag: FC<Props> = ({ children, name, hints }) => {
  const NEXT_PUBLIC_appEnv = config.appEnv;
  const { t } = useTranslation();
  const [display, setDisplay] = useState<boolean>(false);
  const [hideFlag, setHideFlag] = useState<boolean>(
    localStorage.getItem("hideFlag") === "true" ? true : false
  );

  useEffect(() => {
    window.addEventListener("storage", () => {
      const flag = localStorage.getItem("hideFlag");
      setHideFlag(flag === "true" ? true : false);
    });
  }, []);
  /* const buildSearch = (params: string) => {
    const search = params.split("__");

    if (search.length === 1) {
      return `ew: terms: ${search[0]}`;
    } else {
      return search.reverse().reduce((acc, curr) => {
        if (acc === "") {
          acc = curr;
        } else {
          acc = `${acc} context: ${curr}`;
        }
        return acc;
      }, "");
    }
  }; */
  const buildUrl = (urlParms: string) => {
    const url = urlParms.split("__");

    if (url.length === 1) {
      return `ew%3A+terms%3A+${url[0]}`;
    } else {
      return url.reverse().reduce((acc, curr) => {
        if (acc === "") {
          acc = curr;
        } else {
          acc = `${acc}+context%3A+${curr}`;
        }
        return acc;
      }, "");
    }
  };
  const languageOptions: LanguageOptions = {
    sv: {
      icon: "/img/sweden-flag.svg",
      label: t("swedish"),
      id: 156,
    },
    en: {
      icon: "/img/britain-flag.svg",
      label: t("english"),
      id: 43,
    },
  };
  return NEXT_PUBLIC_appEnv === "staging" && !hideFlag ? (
    <>
      <Accordion
        expanded={display}
        sx={{ border: "1px solid orange", borderRadius: "0.8rem", my: 2 }}
      >
        <AccordionSummary
          expandIcon={!!children && <ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          sx={{
            backgroundColor: "orange",
            borderRadius: 1,
            "& .MuiAccordionSummary-content": { alignItems: "center" },
          }}
          onClick={() => !!children && setDisplay(!display)}
        >
          {hints && hints.length > 0 && (
            <HtmlTooltip
              title={
                <>
                  <Typography variant="h6">Translation Keys:</Typography>
                  {hints?.map((hint, index) => {
                    return (
                      <Typography
                        key={index}
                        sx={{ display: "flex" }}
                        variant="subtitle2"
                      >
                        {" "}
                        {/* <IconButton
                          aria-label="toggle password visibility"
                          onClick={() =>
                            navigator.clipboard.writeText(buildSearch((hint as string)) || "")
                          }
                        >
                          <ContentCopy />
                        </IconButton> */}
                        {(Object.keys(languageOptions) as Language[]).map(
                          (language) => (
                            <a
                              href={`https://poeditor.com/projects/po_edit?per_page=20&id=529721&id_language=${
                                languageOptions[language]?.id
                              }&filter=&order=&search=${buildUrl(hint)}`}
                              target={"_blank"}
                              rel="noreferrer"
                              style={{ marginRight: "0.5rem" }}
                            >
                              <img
                                alt={languageOptions[language]?.label}
                                src={languageOptions[language]?.icon}
                              />
                            </a>
                          )
                        )}
                        {"  "}
                        <a
                          href={`https://poeditor.com/projects/po_edit?per_page=20&id=529721&id_language=${
                            languageOptions[
                              !localStorage.getItem("lang") ||
                              localStorage.getItem("lang") === "disabled"
                                ? "en"
                                : localStorage.getItem("lang")!
                            ]?.id
                          }&filter=&order=&search=${buildUrl(hint)}`}
                          target={"_blank"}
                          rel="noreferrer"
                        >
                          {hint}
                        </a>
                      </Typography>
                    );
                  })}
                </>
              }
            >
              <InfoIcon fontSize="large" sx={{ mr: 1 }} />
            </HtmlTooltip>
          )}
          <Typography variant="subtitle1">
            <strong>Staging Flag: {name}</strong>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>{children}</AccordionDetails>
      </Accordion>
    </>
  ) : null;
};

export default StagingFlag;
