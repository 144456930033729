import { IMockedData } from "../../app_store";
import { EACTIONS } from "../../action.enum";
import { IMockedDataAction } from "./mocked-data.action";

export function setMockedDataAction(
  mockedData: IMockedData
): IMockedDataAction {
  return {
    type: EACTIONS.SET_MOCKED_DATA,
    payload: mockedData,
  };
}
