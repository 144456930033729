export const generateAvailableDate = (option) => {
  let result = new Date();
  const today = new Date();
  today.setHours(0, 0, 0, 0);
  switch (option) {
    case "date__week":
      const weekDate = new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate() - 7,
        0,
        0,
        0,
        0
      );
      result = weekDate;
      break;
    case "date__month":
      const monthDate = new Date(
        today.getFullYear(),
        today.getMonth() - 1,
        today.getDate(),
        0,
        0,
        0,
        0
      );
      result = monthDate;

      break;
    case "date__quarter":
      const quarterDate = new Date(
        today.getFullYear(),
        today.getMonth() - 3,
        today.getDate(),
        0,
        0,
        0,
        0
      );
      result = quarterDate;
      break;
    case "date__year":
      const yearDate = new Date(
        today.getFullYear() - 1,
        today.getMonth(),
        today.getDate(),
        0,
        0,
        0,
        0
      );
      result = yearDate;
      break;
    case "date__all":
      const twoYearsDate = new Date(
        today.getFullYear() - 2,
        today.getMonth(),
        today.getDate(),
        0,
        0,
        0,
        0
      );
      result = twoYearsDate;
      break;
    case "date__custom":
      result = today;
      break;
    default:
      break;
  }
  return result;
};
