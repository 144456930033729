import { useEffect, useState } from "react";

import { Box, Button, Container, Grid, Modal, Typography } from "@mui/material";
import type { NextPage } from "next";
import Head from "next/head";

import { subDays, subWeeks } from "date-fns";
import costumFormat from "date-fns/format";

import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
//import { apiRequestHandler } from "../api/requestHandler";
import { DashboardLayout } from "../components/layout/dashboard-layout/dashboard-layout";
import { MainContent } from "../components/mainContent";
import { setDateRangeAction } from "../redux/action/date-range";
import GuiderComponent from "../components/guider";
import config from "../utils/config";
import StagingFlag from "../components/staging-flag";
import SearchArticlesComponent from "../components/overview/overview-search-products";
import OverviewCostSummary from "../components/overview/overview-cost-summary";
import CostBreakdownWrapper from "./price-monitoring/overview-cost-component";
import OverviewPriceAlert from "../components/overview/overview-price-alert";
import { useSelector } from "react-redux";
import { IReduxState } from "../redux/app_store";
import { EONBOARDING_STATUS } from "../enums/onboarding-status.enum";
import CheckIcon from "@mui/icons-material/Check";
import { useRouter } from "next/router";
import { generateAvailableDate } from "../utils/date-selection";
//import YearMonthFilter from "../components/filters/year-month-filter";

const Overview: NextPage = () => {
  const NEXT_PUBLIC_appEnv = config.appEnv;
  const { t } = useTranslation();
  const [selectedRange] = useState<"week" | "month" | "year">("week");
  const [startDate] = useState(new Date(subWeeks(Date.now(), 1)));
  const [endDate] = useState(new Date(Date.now()));
  const [isChangedRange] = useState<boolean>(false);
  const [stagingGuideTrigger, setStagingGuideTrigger] =
    useState<boolean>(false);
  const dispatch = useDispatch();
  const router = useRouter();
  const { user } = useSelector((store: IReduxState) => store);
  const currentDate = new Date();
  //@ts-ignore
  const [defaultDateFilter, setDefaultDateFilter] = useState<any>(
    router.query?.fromDate || "date__month"
  );
  const [startDateFilter, setStartDateFilter] = useState<any>(
    subDays(currentDate, 30).toISOString().split("T")[0]
  );
  const [endDateFilter, setEndDateFilter] = useState<any>(
    currentDate.toISOString().split("T")[0]
  );
  useEffect(() => {
    if (router.query.fromDate) {
      setDefaultDateFilter(router.query?.fromDate as string);
      if (router.query.fromDate !== "date__custom") {
        setStartDateFilter(generateAvailableDate(router.query?.fromDate));
      }
    }
    if (router.query.startDate) {
      setStartDateFilter(router.query.startDate);
    }
    if (router.query.endDate) {
      setEndDateFilter(router.query.endDate);
    }
  }, [router.query]);

  const isMockedData =
    user?.onboardingStatus === EONBOARDING_STATUS.ESIGN_DOCUMENT_SIGNED;

  const openMockedDataModalInitialStatus = () =>
    localStorage.getItem("showMockDataPopUp") === null &&
    localStorage.getItem("onboarding") === "false" &&
    isMockedData;

  const [openMockedDataModal, setOpenMockedDataModal] = useState(
    openMockedDataModalInitialStatus()
  );

  const onGuideCompleted = () => {
    openMockedDataModalInitialStatus() && setOpenMockedDataModal(true);
  };

  const handleCloseMockModal = (_) => {
    localStorage.setItem("showMockDataPopUp", "false");
    setOpenMockedDataModal(false);
  };

  const boxWrapper = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "100%",
    maxWidth: 500,
    bgcolor: "#323232",
    borderRadius: "25px",
    backgroundColor: "#F24868",
    boxShadow: "0px 25px 50px rgba(100, 116, 139, 0.25)",
    p: "6.4rem",
  };
  const boxContent = {
    textAlign: "center",
    color: "#fff",
  };
  const boxAction = {
    display: "flex",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
  };

  const MockedDataModal = ({ open, content, actions }: any) => {
    return (
      <Modal open={open} onClose={close}>
        <Box sx={boxWrapper}>
          <Box sx={boxContent}>{content}</Box>
          <Box sx={boxAction}>{actions}</Box>
        </Box>
      </Modal>
    );
  };
  /* function isDifferentMonthAndYear(inputDate) {
    // Get the current date
    const currentDate = new Date();

    // Extract the current month and year
    const currentMonth = currentDate.getMonth();
    const currentYear = currentDate.getFullYear();

    // Extract the month and year from the input date
    // Assuming inputDate is a Date object
    const inputMonth = inputDate.getMonth();
    const inputYear = inputDate.getFullYear();

    // Check if they are different
    return inputMonth !== currentMonth || inputYear !== currentYear;
  } */
  useEffect(() => {
    if (isChangedRange) {
      const nextRange: Types.DateRange = {
        period: selectedRange,
        from: costumFormat(startDate, "yyyy-MM-dd"),
        to: costumFormat(endDate, "yyyy-MM-dd"),
      };
      dispatch(setDateRangeAction(nextRange));
    }
  }, [selectedRange]);
  return (
    <>
      <Head>
        <title>
          {t("dashboard")}: {t("overview")} | Parsly
        </title>
      </Head>

      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 3,
        }}
      >
        <Container maxWidth="xl">
          {NEXT_PUBLIC_appEnv === "staging" ? (
            <StagingFlag name={`Welcome Guide`}>
              <GuiderComponent
                open={stagingGuideTrigger}
                onGuideCompleted={onGuideCompleted}
              />
              <Button
                variant="outlined"
                onClick={() => setStagingGuideTrigger(!stagingGuideTrigger)}
              >
                Open Guide
              </Button>
            </StagingFlag>
          ) : null}
          <MainContent onGuideCompleted={onGuideCompleted} />
          <SearchArticlesComponent />
          {/* <Grid container justifyContent="flex-end" spacing={3} marginTop={2}>
              <Grid item sx={{ display: "flex", alignItems: "flex-end" }}>
                <YearMonthFilter
                  startDate={startDateFilter}
                  endDate={endDateFilter}
                  endDateHandler={setEndDateFilter}
                  startDateHandler={setStartDateFilter}
                />
              </Grid>
            </Grid> */}
          <Grid container spacing={3} sx={{ justifyContent: "flex-start" }}>
            <Grid item md={4} xs={12}>
              <OverviewCostSummary currentDate={endDateFilter} />
            </Grid>
            <Grid item md={8} sm={12}>
              <CostBreakdownWrapper
                startDate={endDateFilter}
                endDate={startDateFilter}
              />
            </Grid>
          </Grid>

          {/* {isDifferentMonthAndYear(new Date(endDateFilter)) && (
            <Grid container spacing={3} sx={{ justifyContent: "flex-start" }}>
              <Grid item xs={12}>
                <Box sx={{ mt: 4 }}>
                  <Alert severity="warning">
                    <Typography variant="body2">
                      {t("main_dashboard__alert_message")}
                    </Typography>
                  </Alert>
                </Box>
              </Grid>
            </Grid>
          )} */}
          <Grid container spacing={3} sx={{ justifyContent: "flex-start" }}>
            <Grid item xs={12}>
              <OverviewPriceAlert />
            </Grid>
          </Grid>
        </Container>
      </Box>
      <MockedDataModal
        open={openMockedDataModal}
        close={handleCloseMockModal}
        content={
          <>
            <Typography variant="h6" sx={{ mb: 2 }}>
              {t("mocked_data__alert_modal__title")}
            </Typography>
            <Typography variant="body1" sx={{ mb: 2 }}>
              {t("mocked_data__alert_modal__description")}
            </Typography>
          </>
        }
        actions={
          <>
            <Button
              variant="contained"
              color="primary"
              style={{ backgroundColor: "#000" }}
              startIcon={<CheckIcon />}
              onClick={handleCloseMockModal}
            >
              {t("mocked_data__alert_modal__button__confirm")}
            </Button>
          </>
        }
      />
    </>
  );
};

Overview.getLayout = (page) => <DashboardLayout>{page}</DashboardLayout>;
export default Overview;
