import { createSvgIcon } from "@mui/material/utils";

export const Invoice = createSvgIcon(
  <svg viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15 15.5H15.5V15V13V12.5H15H3H2.5V13V15V15.5H3H15ZM15 11.5H15.5V11V9V8.5H15H3H2.5V9V11V11.5H3H15ZM15 7.5H15.5V7V5V4.5H15H3H2.5V5V7V7.5H3H15ZM1.85355 1.85355L3 0.707107L4.14645 1.85355L4.5 2.20711L4.85355 1.85355L6 0.707107L7.14645 1.85355L7.5 2.20711L7.85355 1.85355L9 0.707107L10.1464 1.85355L10.5 2.20711L10.8536 1.85355L12 0.707107L13.1464 1.85355L13.5 2.20711L13.8536 1.85355L15 0.707107L16.1464 1.85355L16.5 2.20711L16.8536 1.85355L17.5 1.20711V18.7929L16.8536 18.1464L16.5 17.7929L16.1464 18.1464L15 19.2929L13.8536 18.1464L13.5 17.7929L13.1464 18.1464L12 19.2929L10.8536 18.1464L10.5 17.7929L10.1464 18.1464L9 19.2929L7.85355 18.1464L7.5 17.7929L7.14645 18.1464L6 19.2929L4.85355 18.1464L4.5 17.7929L4.14645 18.1464L3 19.2929L1.85355 18.1464L1.5 17.7929L1.14645 18.1464L0.5 18.7929V1.20711L1.14645 1.85355L1.5 2.20711L1.85355 1.85355Z"
      fill="currentColor"
      stroke="currentColor"
    />
  </svg>,
  "Invoice"
);
