import countries from "../../public/countries.json";

const CurrencyFormat = (value: string, formatProps: FormatProps) => {
  return (
    formatProps &&
    parseFloat(value).toLocaleString(formatProps.locale, {
      style: "currency",
      currency: formatProps.currency,
    })
  );
};

type Currency = "SEK" | "USD";

type Locale = "sv-SE" | "en-US";

export type FormatProps = { locale: Locale; currency: Currency };

export const currencyMap: { [key in Types.CountryId]: Currency } = {
  SE: "SEK",
  US: "USD",
};

const currencies = countries.reduce(
  (a, v) => ({ ...a, [v.code]: { locale: "en-US", currency: "USD" } }),
  {}
);

export const currencyFormatMap: { [key in Types.CountryId]: FormatProps } = {
  ...currencies,
  SE: { locale: "sv-SE", currency: "SEK" },
  US: { locale: "en-US", currency: "USD" },
};

export default CurrencyFormat;
